<template>
  <v-dialog
    v-model="createTagModal"
    max-width="650px"
    @click:outside="closeCreateTagModal()"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Add Tag</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form" class="mx-2" lazy-validation>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="name"
                  label="Tag*"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <small>* indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="closeCreateTagModal()"> Close </v-btn>
        <v-btn color="primary" text @click="createTag()"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      name: "",
    };
  },
  props: {
    createTagModal: Boolean,
    selectedClient: String,
    selectedProspect: String,
  },
  methods: {
    closeCreateTagModal() {
      this.$emit("closeCreateTagModal");
    },
    async createTag() {
      if (!this.$refs.form.validate()) {
        return;
      }

      try {
        const response = await this.$http.post("/clients/tags", {
          name: this.name,
          clientId: this.selectedClient,
          prospectId: this.selectedProspect,
        });
        this.$emit("clientTagSaved", response.data);
      } catch (err) {
        this.$emit("failedCreatingClientTag", err);
      }
    },
  },
};
</script>
