<template>
  <div class="prospect">
    <edit-prospect
      :edit-modal="editModal"
      :selected-client="selectedClient"
      :prospect="prospect || {}"
      @closeEditModal="closeEditModal"
      @failedEditingProspect="failedEditingProspect"
      @prospectEditSaved="prospectEditSaved"
    ></edit-prospect>
    <create-client-tag
      :create-tag-modal="createTagModal"
      :selected-client="selectedClient"
      :selected-prospect="prospect._id"
      @closeCreateTagModal="closeCreateTagModal"
      @failedCreatingClientTag="failedCreatingClientTag"
      @clientTagSaved="clientTagSaved"
    ></create-client-tag>
    <v-row>
      <v-spacer></v-spacer>
      <v-btn
        fab
        dark
        small
        color="red darken-2"
        @click="deleteProspect()"
        class="mx-2"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-btn
        fab
        dark
        small
        color="primary"
        @click="editModal = true"
        class="mx-2"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-card flat>
          <v-card-title class="text-h6"> Prospect Details </v-card-title>
          <v-card-text>
            <v-container>
              <v-list two-line>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon> mdi-briefcase-outline </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title
                      >{{ prospect.companyName }}
                      <v-badge
                        offset-x="0"
                        offset-y="-5"
                        overlap
                        dot
                        color="green"
                        v-if="prospect.prospectStatus === 'ACTIVE'"
                      ></v-badge>
                      <v-badge
                        offset-x="0"
                        offset-y="-5"
                        overlap
                        dot
                        color="yellow"
                        v-else-if="prospect.prospectStatus === 'NEW'"
                      ></v-badge>
                      <v-badge
                        offset-x="0"
                        offset-y="-5"
                        overlap
                        dot
                        color="red"
                        v-else
                      ></v-badge>
                    </v-list-item-title>
                    <v-list-item-subtitle>Business Name</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="prospect.website">
                  <v-list-item-icon>
                    <v-icon> mdi-website </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title
                      ><a :href="`https://${prospect.website}`" target="_blank"
                        >https://{{ prospect.website }}</a
                      ></v-list-item-title
                    >
                    <v-list-item-subtitle>Company Website</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider inset></v-divider>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon> mdi-account-outline </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title
                      >{{ prospect.firstName }}
                      {{ prospect.lastName }}</v-list-item-title
                    >
                    <v-list-item-subtitle>Full Name</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="prospect.title">
                  <v-list-item-icon>
                    <v-icon> mdi-tie </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ prospect.title }}</v-list-item-title>
                    <v-list-item-subtitle>Title</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon> mdi-email-outline </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title
                      ><a :href="`mailto:${prospect.email}`">{{
                        prospect.email
                      }}</a></v-list-item-title
                    >
                    <v-list-item-subtitle>Work Email</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="prospect.phone">
                  <v-list-item-icon>
                    <v-icon> mdi-phone-outline </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title
                      ><a :href="`tel:${prospect.phone}`">{{
                        prospect.phone
                      }}</a></v-list-item-title
                    >
                    <v-list-item-subtitle>Phone Number</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="prospect.linkedInProfileUrl">
                  <v-list-item-icon>
                    <v-icon> mdi-linkedin </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title
                      ><a
                        :href="`${prospect.linkedInProfileUrl}`"
                        target="_blank"
                        >{{ prospect.linkedInProfileUrl }}</a
                      ></v-list-item-title
                    >
                    <v-list-item-subtitle
                      >LinkedIn Profile URL</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-container>
          </v-card-text>
        </v-card>
        <v-card flat class="my-4">
          <v-card-title class="text-h6"> Tags </v-card-title>
          <v-card-text>
            <v-container>
              <v-autocomplete
                v-model="prospectTags"
                :items="tags"
                :item-value="(tag) => tag._id"
                :item-text="(tag) => tag.name"
                @change="updateTags"
                chips
                multiple
              ></v-autocomplete>
              <v-btn small text color="accent" @click="createTagModal = true"
                >Create new tag</v-btn
              >
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card flat>
          <v-card-title class="text-h6"> Activity Feed </v-card-title>
          <v-card-text>
            <v-container>
              <v-timeline dense clipped>
                <v-slide-x-transition group>
                  <v-timeline-item
                    v-for="event in timeline"
                    :key="event._id"
                    class="mb-4"
                    :color="getColor(event)"
                    small
                  >
                    <v-row justify="space-between">
                      <v-col cols="7" v-text="event.text"></v-col>
                      <v-col
                        class="text-right"
                        cols="5"
                        v-text="event.addedAt"
                      ></v-col>
                    </v-row>
                  </v-timeline-item>
                </v-slide-x-transition>
              </v-timeline>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
import EditProspect from "@/components/EditProspect.vue";
import CreateClientTag from "@/components/CreateClientTag.vue";
import { store } from "@/state/store.js";

export default {
  components: {
    EditProspect,
    CreateClientTag,
  },
  data() {
    return {
      prospect: {},
      eventColors: [
        {
          type: "CREATED_EVENT",
          color: "primary",
        },
        {
          type: "CAMPAIGN_EVENT",
          color: "primary",
        },
        {
          type: "RECEIVED_EVENT",
          color: "primary",
        },
        {
          type: "OPENED_EVENT",
          color: "pink",
        },
        {
          type: "REPLIED_EVENT",
          color: "pink",
        },
        {
          type: "LEAD_EVENT",
          color: "green",
        },
        {
          type: "LOST_EVENT",
          color: "red darken-2",
        },
      ],
      tags: [],
      prospectTags: [],
      events: [],
      store,
      editModal: false,
      createTagModal: false,
    };
  },
  created() {
    this.loadProspect();
    this.loadEvents();
    this.loadTags();
  },
  computed: {
    timeline() {
      return this.events.slice().reverse();
    },
    selectedClient() {
      return this.store.selectedClient;
    },
  },
  methods: {
    async loadEvents() {
      try {
        const eventsResponse = await this.$http.get(
          `/prospects/events/${this.$route.params.prospectId}`
        );
        this.events = eventsResponse.data.map((event) => {
          return {
            _id: event._id,
            addedAt: moment(event.addedAt).format("MMM Do YYYY hh:mm:ss"),
            text: event.text,
            type: event.type,
          };
        });
      } catch (error) {
        console.log(error);
      }
    },
    async loadTags() {
      try {
        const tagsResponse = await this.$http.get(
          `/clients/tags/${this.selectedClient}`
        );
        this.tags = tagsResponse.data;

        const prospectTagsResponse = await this.$http.get(
          `/prospects/tags/${this.$route.params.prospectId}`
        );

        if (prospectTagsResponse.data.tags) {
          this.prospectTags = prospectTagsResponse.data.tags;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async loadProspect() {
      try {
        const prospectResponse = await this.$http.get(
          `/prospects/${this.$route.params.prospectId}`
        );
        this.prospect = prospectResponse.data;
      } catch (error) {
        console.log(error);
      }
    },
    async updateTags() {
      try {
        await this.$http.post(`/prospects/tags/${this.prospect._id}`, {
          tags: this.prospectTags,
        });

        this.loadTags();
      } catch (error) {
        console.log(error);
      }
    },
    async deleteProspect() {
      try {
        await this.$http.delete(`/prospects/${this.prospect._id}`);
        this.$router.push({ name: "prospects" });
      } catch (error) {
        console.log(error);
      }
    },
    getColor(event) {
      return this.eventColors.find((ec) => ec.type === event.type).color;
    },
    closeEditModal() {
      this.editModal = false;
    },
    failedEditingProspect(error) {
      console.log(error);
      this.editModal = false;
    },
    prospectEditSaved() {
      this.loadProspect();
      this.editModal = false;
    },
    closeCreateTagModal() {
      this.createTagModal = false;
    },
    failedCreatingClientTag(error) {
      console.log(error);
      this.createTagModal = false;
    },
    clientTagSaved() {
      this.loadTags();
      this.createTagModal = false;
    },
  },
};
</script>
