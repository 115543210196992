<template>
  <v-dialog
    v-model="editModal"
    max-width="650px"
    @click:outside="closeEditModal()"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Edit Prospect</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form" class="mx-2" lazy-validation>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="prospect.companyName"
                  label="Company Name*"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="prospect.firstName"
                  label="First Name*"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="prospect.lastName"
                  label="Last Name*"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="prospect.email"
                  label="Email Address*"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="prospect.phone"
                  label="Phone"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="prospect.title"
                  label="Title"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="prospect.linkedInProfileUrl"
                  label="LinkedIn Profile Url"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="prospect.website"
                  label="Company Website"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <small>* indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="closeEditModal()"> Close </v-btn>
        <v-btn color="primary" text @click="saveProspect()"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    editModal: Boolean,
    selectedClient: undefined,
    prospect: Object,
  },
  methods: {
    closeEditModal() {
      this.$emit("closeEditModal");
    },
    async saveProspect() {
      if (!this.$refs.form.validate()) {
        return;
      }

      try {
        const response = await this.$http.put(
          `/prospects/${this.prospect._id}`,
          this.prospect
        );
        this.$emit("prospectEditSaved", response.data);
      } catch (err) {
        this.$emit("failedEditingProspect", err);
      }
    },
  },
};
</script>
